import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import home from '../pages/home'

let haojise = ()=>import('../pages/haojise')
let gongsi = ()=>import('../pages/gongsi')
let news_list = ()=>import('../pages/news_list')
let news_detail = ()=>import('../pages/news_detail')
let hezuo = ()=>import('../pages/hezuo')


export default new Router({
    routes:[
     {
        path:'/',
        component:home
     },
     {
      path:'/haojise',
      component:haojise
   },
   {
      path:'/gongsi',
      component:gongsi
   },
   {
      path:'/news_list',
      component:news_list
   },
   {
      path:'/news_detail',
      component:news_detail
   },
   {
      path:'/hezuo',
      component:hezuo
   },
   ]
})

